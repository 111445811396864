<template>
  <div style="top: 0px; position: absolute; right: 0px">
    <VMenu
      bottom
      left
      absolute
      :close-on-content-click="true"
    >
      <template #activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          v-on="on"
          icon
          :style="{
            'z-index': onlyVisibilityMenu && disableVisibility ? 0 : 6,
            'cursor': onlyVisibilityMenu && disableVisibility ? 'default' : 'pointer',
          }"
          @click.prevent
        >
          <VIcon
            :style="{
              'font-size': '24px',
            }"
            :color="iconColor"
          >
            mdi-dots-horizontal
          </VIcon>
        </VBtn>
      </template>
      <VCard class="card-menu-items">
        <template v-if="!disableVisibility">
          <VList class="pt-1">
            <VListItem>
              <VIcon
                size="20px"
                class="pr-2"
              >
                mdi-eye
              </VIcon>
              <VListItemTitle>
                Visibility
              </VListItemTitle>
            </VListItem>
          </VList>
          <VDivider />
          <VList class="pt-3">
            <VListItem v-if="$isUserAdmin && $viewProgramAsAdmin">
              <VCheckbox
                v-model="isCardHidden"
                dense
                color="success"
                class="view-options"
                label="Hidden"
                @change="resetVisibleTo"
              />
            </VListItem>
            <VListItem
              v-for="(option, index) in viewOptions"
              :key="index"
            >
              <VListItemTitle>
                <VCheckbox
                  v-model="visibleTo"
                  dense
                  multiple
                  color="success"
                  class="view-options"
                  :label="option.title"
                  :value="option.value"
                  @change="setViewOption"
                />
              </VListItemTitle>
            </VListItem>
          </VList>
        </template>
        <slot
          v-if="enableStatusAction"
          name="status-action"
        />
        <slot
          v-if="!onlyVisibilityMenu"
          name="additionalMenu"
        >
          <VDivider v-if="$isUserAdmin && !disableVisibility" />
          <VList v-if="$isUserAdmin || (checkMentorPermission(modelType.toLowerCase(), parentModel) && belongsToMe(item.user_id))">
            <VListItem @click="$bus.$emit('editSectionItem', modelType, item)">
              <VIcon
                size="20px"
                class="pr-2"
              >
                mdi-square-edit-outline
              </VIcon>
              <VListItemTitle>Edit</VListItemTitle>
            </VListItem>
            <VListItem @click="$bus.$emit('renameSectionItem', modelType, item)">
              <VIcon
                size="20px"
                class="pr-2"
              >
                mdi-rename-box
              </VIcon>
              <VListItemTitle>Rename</VListItemTitle>
            </VListItem>
            <VListItem
              v-if="enableDuplicate"
              @click="onDuplicateItem(item)"
            >
              <VIcon
                size="20px"
                class="pr-2"
              >
                content_copy
              </VIcon>
              <VListItemTitle>Duplicate</VListItemTitle>
            </VListItem>
            <VListItem
              v-if="itemType == 'Deliverable' && (!$isUserStudent || !$viewProgramAsStudent)"
              @click="$bus.$emit('viewSubmitted', item)"
            >
              <VIcon
                size="20px"
                class="pr-2"
              >
                list_alt
              </VIcon>
              <VListItemTitle>View Submitted</VListItemTitle>
            </VListItem>
            <VListItem @click="onCopyLink">
              <VIcon
                size="20px"
                class="pr-2"
              >
                link
              </VIcon>
              <VListItemTitle>Copy Link</VListItemTitle>
            </VListItem>
            <VListItem @click="onRemoveItem(item)">
              <VIcon
                size="20px"
                class="pr-2"
              >
                mdi-book-remove
              </VIcon>
              <VListItemTitle>Remove from {{ featureName(parentModelType).singularize() }}</VListItemTitle>
            </VListItem>
            <VListItem @click="doDeleteItem">
              <VIcon
                size="20px"
                color="error"
                class="pr-2"
              >
                mdi-delete
              </VIcon>
              <VListItemTitle style="color: #ff5252;">
                Delete Forever
              </VListItemTitle>
            </VListItem>
          </VList>
        </slot>
      </VCard>
    </VMenu>
    <Confirm
      ref="confirmDuplicate"
      confirm-color="info"
      cancel-color="disabled"
    />
    <Confirm ref="confirmDelete" />
    <Confirm ref="confirmRemove" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import FormMixin from "@/mixins/Form";
import DeleteOptionsMixin from "@/mixins/DeleteOptions";
import SupportsModelTypes from "@/mixins/SupportsModelTypes";

export default {
  name: "CardMenu",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentModel: {
      type: Object,
      default: null,
    },
    itemType: {
      type: String,
      default: "Program",
    },
    parentModelType: {
      type: String,
      default: "Program",
    },
    iconColor: {
      type: String,
      default: "inherit",
    },
    onlyVisibilityMenu: {
      type: Boolean,
      default: false,
    },
    enableStatusAction: {
      type: Boolean,
      default: true,
    },
    disableVisibility: {
      type: Boolean,
      default: false,
    },
    enableDuplicate: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [SearchMixin, FormMixin, DeleteOptionsMixin, SupportsModelTypes],
  data() {
    return {
      isCardHidden: false,
      visibleTo: [],
    };
  },
  watch: {
    itemType(value) {
      this.modelType = value;
    },
    "item.visible_to": {
      handler: function(value) {
        if(this.itemType.toLowerCase() == "program") {
          if (value instanceof Array && value.length === 0) {
            this.visibleTo = [];
            this.isCardHidden = true;
          } else {
            this.visibleTo = Object.keys(value);
            this.isCardHidden = false;
          }
        }
      },
      deep: true,
    },
    "item.pivot.visible_to": {
      handler: function(value) {
        if(this.itemType.toLowerCase() != "program") {
          let visibility = JSON.parse(value);
          if (visibility instanceof Array && visibility.length === 0) {
            this.visibleTo = [];
            this.isCardHidden = true;
          } else {
            let roles = Object.keys(visibility);
            this.visibleTo = roles.length === 0 ? [] : roles;
            this.isCardHidden = roles.length === 0;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    if(this.itemType.toLowerCase() == "program") {
      if (
        Array.isArray(this.item.visible_to) &&
        this.item.visible_to.length === 0
      ) {
        this.isCardHidden = true;
      } else {
        this.visibleTo = this.item.visible_to ? Object.keys(this.item.visible_to) : [];
        // Set card hidden when only org admin is available in Visible To
        this.isCardHidden = this.visibleTo.length === 1 && this.visibleTo[0] === "organization-admin";
      }
    } else if(this.item.pivot) {
      this.setLocalVisibility(this.item);
    } else if(this.item.hasOwnProperty("programs")) {
      const currentProgram = this.item.programs.find((program) => program.id === this.parentModel.id);
      this.setLocalVisibility(currentProgram);
    }
    // Set these values to support appropriate mixins
    this.deleteItem = this.item;
    this.deleteItemType = this.itemType;
    this.modelType = this.itemType;
  },
  computed: {
    viewOptions() {
      return [
        {
          title: "Visible to " + this.featureName("Students", "pluralize"),
          value: "student",
          enable: !this.$isUserStudent && !this.$viewProgramAsStudent,
        },
        {
          title: "Visible to " + this.featureName("Mentors", "pluralize"),
          value: "mentor",
          enable: this.$isUserAdmin && this.$viewProgramAsAdmin,
        },
      ].filter((item) => item.enable === true);
    },
  },
  mounted() {
    this.$emit("visibilityStatus", this.isCardHidden, this.item);
  },
  methods: {
    ...mapActions({
      doSaveContent: "content/save",
      doSaveDeliverable: "deliverable/save",
      doSaveFaq: "faq/save",
      doSaveLesson: "lesson/save",
      doSaveProgram: "program/save",
      doDuplicateContent: "content/duplicate",
      doDuplicateDeliverable: "deliverable/duplicate",
      doDuplicateFaq: "faq/duplicate",
      doSaveContentVisibility: "team/contentVisibility",
    }),
    onDuplicate(params, item) {
      if(this.parentModel) {
        params.program_id = [this.parentModel.id];
        params.identifier = params.program_id + "-" + params.identifier;
      }
      return this[`doDuplicate${this.modelType}`](params).then((result) => {
        if(result.success) {
          const newTempItem = {
            ...item,
            isDuplicating: false,
            processingDuplicate: true,
            title: "Copy of " + item.title,
            identifier: params.identifier,
          };
          this.$delete(newTempItem, "id");
          this.$emit("duplicationDone", newTempItem);
          const itemGroupKey = this.parentModel ? this.parentModelType.toLowerCase() + "-" + this.modelType.toLowerCase() : this.modelType.toLowerCase(); 
          this.setGroupItemLocalStorage("pending-duplications", itemGroupKey, newTempItem.identifier, newTempItem);
        }
        return result;
      });
    },
    resetVisibleTo() {
      this.visibleTo = [];
      this.item.visible_to = this.visibleTo;
      this.isCardHidden = true;
      if(this.itemType == "Program") {
        this.saveVisibleTo();
      } else {
        this.setProgramContentVisibility();
      }
    },
    setViewOption() {
      this.item.visible_to = this.visibleTo;
      this.isCardHidden = this.visibleTo.length === 0;
      if(this.itemType == "Program") {
        this.saveVisibleTo();
      } else {
        this.setProgramContentVisibility();
      }
    },
    setProgramContentVisibility() {
      const params = {
        programId: this.parentModel.id,
        contentId: this.item.id,
        contentType: this.getAppropriateContentType(this.itemType),
        visibleTo: this.item.visible_to.reduce((roles, role) => ({ ...roles, [role]: role.capitalize() }), {}),
      };
      this.doSaveContentVisibility(params).then((result) => {
        if(this.item.pivot) {
          this.$set(this.item.pivot, "visible_to", JSON.stringify(params.visibleTo));
        } else {
          const currentProgramIndex = this.item.programs.findIndex((program) => program.id === this.parentModel.id);
          this.$set(this.item.programs[currentProgramIndex].pivot, "visible_to", JSON.stringify(params.visibleTo));
        }
        this.$set(this.item, "visible_to", result.visible_to);
        this.$emit("visibilityStatus", this.isCardHidden, this.item);
        this.$bus.$emit(
          "notificationMessage",
          "Record updated successfully!"
        );
      }).catch((error) => {
        this.$bus.$emit("notificationError", `Unable to update the record!`);
      });
    },
    saveVisibleTo() {
      let method = `doSave${this.modelType.capitalize()}`;
      this[method]({
        id: this.item.id,
        visible_to: this.item.visible_to,
      })
        .then((result) => {
          this.$set(this.item, "visible_to", result.data.visible_to);
          this.$emit("visibilityStatus", this.isCardHidden, this.item);
          this.$bus.$emit(
            "notificationMessage",
            "Record updated successfully!"
          );
        })
        .catch((error) => {
          this.$bus.$emit("notificationError", `Unable to update the record!`);
        });
    },
    getAppropriateContentType(type) {
      switch (type) {
        case "lesson":
          return "lesson";
        case "Content":
          return "primaryContent";
        default:
          return type.toLowerCase();
      }
    },
    setLocalVisibility(item) {
      let visibility = item.pivot.visible_to ? JSON.parse(item.pivot.visible_to) : [];
      if (Array.isArray(visibility) && visibility.length === 0) {
        this.isCardHidden = true;
      } else {
        let roles = Object.keys(visibility);
        this.visibleTo = roles.length > 0 ? roles : [];
        this.isCardHidden = roles.length === 0 || (roles.length === 1 && roles[0] === "organization-admin");
      }
    },
    onCopyLink() {
      if(this.modelTypeRoute == "deliverable") {
        this.copyTextToClipboard(this.getFullyQualifiedUrlForItem(`${this.modelTypeRoute}.submit`, { deliverable: this.item.id }));
      } else {
        this.copyTextToClipboard(this.getFullyQualifiedUrlForItem(`${this.modelTypeRoute}.view`, this.item.id));
      }
    },
  },
};
</script>
<style lang="scss">
.card-menu-items {
  overflow: hidden;
  .v-list-item {
    min-height: 36px !important;
    height: 36px !important;
  }
  .v-list-item__title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .v-radio {
    border: none !important;
  }
}
</style>
