var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSkeletonLoader',{attrs:{"grid-list-lg":"","fluid":"","type":"card","loading":_vm.isFirstLoad && !_vm.isDashboard}},[_c('VRow',{attrs:{"row":"","wrap":""}},[(_vm.deliverables.data.length === 0)?_c('VCol',{attrs:{"cols":"12"}},[_c('NoRecordCard',{attrs:{"action-text":`Create a ${ _vm.featureName(_vm.tabName, 'singularize') }`,"title":`You don’t have any ${ _vm.featureName(_vm.tabName) } yet`,"description":`You can create ${ _vm.featureName(_vm.tabName).toLowerCase() } relevant to your ${ _vm.featureName('Program', 'singularize') } and make them available to ${ _vm.featureName('Students') } and/or ${ _vm.featureName('Mentors') }. Surveys, weekly check-ins, file submissions — ${ _vm.featureName(_vm.tabName).toLowerCase() } are a great way to engage with and track the progress of your team and participants.`},on:{"clicked":function($event){return _vm.$emit('addSection', _vm.sections[_vm.activeTab])}}})],1):[(!_vm.isTilesView)?_c('VCol',{attrs:{"cols":"12"}},[_c('DeliverablesData',{attrs:{"existing-data":_vm.deliverables.data},on:{"duplicated":(result) => _vm.localDeliverables.data.unshift(result),"deleted":(id) =>
              (_vm.localDeliverables.data = _vm.localDeliverables.data.filter(
                (deliverable) => deliverable.id != id
              )),"updated":(result) =>
              (_vm.localDeliverables.data = _vm.localDeliverables.data.map((deliverable) =>
                deliverable.id === result.id ? result : deliverable
              ))}})],1):_c('DataViewUI',{attrs:{"hide-actions":"","hide-main-title":"","hide-records-info":"","default-view-mode":"grid","background-color":"transparent","data":_vm.deliverables,"md":_vm.colMd,"lg":_vm.colLg,"enable-infinite-loader":_vm.enableInfiniteLoader},on:{"infiniteLoad":_vm.onInfiniteLoad},scopedSlots:_vm._u([{key:"card",fn:function(props){return [_c('IndexCard',{attrs:{"hide-actions":"","height":"200px","content-min-height":"150px","loading":props.item.loading || props.item.isDuplicating,"visibility-icon-color":props.item.is_overdue && false ? '#ffffff' : 'inherit',"background-color":props.item.is_overdue && false ? '#ff2a7a' : '#ffffff',"card-shadow":props.item.is_overdue && false
                ? '0 3px 8px 0 rgba(255, 42, 122, 0.5)'
                : '0 3px 8px 0 rgba(216, 220, 240, 0.5)',"item":props.item,"item-type":_vm.tabName,"parent-model":_vm.model,"hide-menu":!_vm.$isUserAdmin || !_vm.$viewProgramAsAdmin,"count":(_vm.model.deliverables_count - _vm.deliverables.data.length) + 1,"show-count":_vm.checkShowCount(props.index),"alt-processing":props.item.processingDuplicate},on:{"onClick":function($event){return _vm.setSubmittedDialogData(props.item, 'deliverables', _vm.$isUserAdmin)},"duplicationDone":_vm.onDuplicationDone,"showMore":function($event){_vm.$emit('toggleTab', _vm.tabName.toLowerCase().pluralize())},"enableItemLoading":(status) => _vm.$set(_vm.localDeliverables.data[props.index], 'loading', status)}},[_c('template',{slot:"title-text"},[_c('span',{staticClass:"text-truncate section-card-title",style:({
                  color: props.item.is_overdue && false ? '#ffffff' : '#000000',
                })},[_vm._v(" "+_vm._s(props.item.title)+" ")])]),_c('template',{slot:"card-content"},[(props.item.metadata && props.item.metadata.length > 0)?_c('VRow',{staticClass:"ma-0 metadata-section"},[_c('VCol',{attrs:{"cols":"12"}},[_c('VList',{staticClass:"metadata"},_vm._l((props.item.metadata.slice(0, 3)),function(question,index){return _c('VListItem',{key:question.id,staticClass:"d-block pa-0 text-truncate"},[_c('VListItemContent',{staticClass:"d-block text-truncate py-0"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(question.name)+" ")])],1)}),1)],1)],1):_vm._e()],1)],2)]}}])}),_c('Dialog',{attrs:{"max-width":"640px","card-text-class":"overflow-hidden","dismissable":false,"persistent":true,"is-dialog-open":_vm.submittedDialogs.deliverables},on:{"toggle:dialog":_vm.onToggleSubmittedDeliverablesDialog},scopedSlots:_vm._u([{key:"default",fn:function(parent){return [(_vm.submitted)?_c('SubmitForm',{attrs:{"mode":"dialog","use-id-from-route":false,"submit-for-user-mode":_vm.submitForUserMode,"id":(_vm.submittedModel.submitted_by_me || {}).id,"existing":_vm.submittedModel.submitted_by_me,"deliverable":_vm.submittedModel,"items":_vm.submitted.data,"parent-uid":parent.uid},on:{"toggle:dialog":_vm.onToggleSubmittedDeliverablesDialog}}):_vm._e()]}}])}),_c('Dialog',{attrs:{"fullscreen":"","is-dialog-open":_vm.submittedDialogs.submitted,"title":`Submitted ${_vm.featureName('Deliverables')}: ${
            _vm.submittedModel.title ? _vm.submittedModel.title : ''
          }`},on:{"toggle:dialog":_vm.onToggleSubmittedDialog}},[_c('Submitted',{attrs:{"mode":"dialog","use-id-from-route":false,"deliverable-id":_vm.submittedModel.id}})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }