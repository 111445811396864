<template>
  <VRow class="community-view">
    <VCol 
      cols="12"
      class="py-2"
    >
      <NoRecordCard 
        v-if="allUsers.length === 0"
        action-text="Add People"
        :disable-action="model.visible_to instanceof Array && model.visible_to.length === 0"
        :title="`Your ${ featureName('Program', 'singularize') } is currently only available to Team Admins`"
        :description="`When you’re ready to add ${ featureName('Students') } and/or ${ featureName('Mentors') } to your ${ featureName('Program', 'singularize') }, click add to start sending out invitations. Once you’ve added ${ featureName('mentors') }, you can assign them additional permissions. `"
        @clicked="dialogs.addPeople.status = true"
      />
      <VCard 
        v-else
        min-height="270px"
      >
        <VCardTitle>
          <VRow>
            <VCol 
              cols="12"
              class="d-flex align-center"
            >
              <span class="label-text">People</span>
              <VSpacer />
              <div class="d-flex align-center">
                <template v-if="$isUserAdmin && $viewProgramAsAdmin">
                  <VTooltip top>
                    <template #activator="{ on }">
                      <VBtn
                        v-on="on"
                        fab
                        width="36px"
                        min-width="36px"
                        height="36px"
                        class="wethrive mr-4"
                        style="border-radius: 10px;"
                        @click="dialogs.permissions = !dialogs.permissions"
                      >
                        <VIcon color="white">
                          mdi-account-key-outline
                        </VIcon>
                      </VBtn>
                    </template>
                    <span>{{ featureName("Users") }} Permissions</span>
                  </VTooltip>
                </template>
                <template v-if="($isUserAdmin && $viewProgramAsAdmin) || checkMentorPermission('invite', model)">
                  <VTooltip top>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <VBtn
                          fab
                          width="36px"
                          min-width="36px"
                          height="36px"
                          class="wethrive mr-4"
                          style="border-radius: 10px;"
                          :disabled="model.visible_to instanceof Array && model.visible_to.length === 0"
                          @click="dialogs.addPeople.status = !dialogs.addPeople.status"
                        >
                          <VIcon color="white">
                            mdi-plus
                          </VIcon>
                        </VBtn>
                      </div>
                    </template>
                    <span v-if="model.visible_to instanceof Array && model.visible_to.length === 0">
                      Set visibility for this {{ featureName("Program", "singularize") }} to enable Invite {{ featureName("Users") }}
                    </span>
                    <span v-else>
                      Invite {{ featureName("Users") }}
                    </span>
                  </VTooltip>
                </template>
                <VTabs
                  v-model="tab"
                  centered
                  background-color="transparent"
                  style="width: auto;"
                >
                  <VTab
                    v-for="(role, index) in roles"
                    :key="index"
                  >
                    {{ role.title }}
                  </VTab>
                </VTabs>
              </div>
            </VCol>
          </VRow>
        </VCardTitle>
        <VCardText class="pt-0">
          <VTabsItems v-model="tab">
            <VTabItem>
              <div 
                v-for="(people, index) in peoples"
                class="pa-2"
                style="display: inline-grid;"
                :key="index"
              >
                <UserModal
                  verticle-view
                  :user="people"
                />
              </div>
              <div
                v-if="isDashboard && allUsers.length > maxPeoplesOnDashboard"
                class="pa-2"
                style="display: inline-grid; position: absolute; cursor: pointer;"
                @click="$emit('toggleTab', 'community')"
              >
                <VAvatar
                  color="#fff3d0"
                  size="70px"
                  class="ma-auto"
                  style="color: #fdbf14;"
                >
                  <strong style="font-size: 28px;">+{{ allUsers.length - maxPeoplesOnDashboard }}</strong>
                </VAvatar>
                <div
                  class="d-flex justify-center"
                  style="width: 70px;"
                >
                  <span
                    class="text-truncate pt-1"
                    style="font-size: 14px; font-weight: 700; color: #fdbf14; "
                  >
                    Expand
                  </span>
                </div>
              </div>
            </VTabItem>
            <VTabItem>
              <div 
                v-for="(people, index) in mentors"
                :key="index"
                class="pa-2"
                style="display: inline-grid;"
              >
                <UserModal
                  verticle-view
                  :user="people"
                />
              </div>
            </VTabItem>
            <VTabItem>
              <div 
                v-for="(people, index) in students"
                :key="index"
                class="pa-2"
                style="display: inline-grid;"
              >
                <UserModal
                  verticle-view
                  :user="people"
                />
              </div>
            </VTabItem>
          </VTabsItems>
        </VCardText>
      </VCard>
    </VCol>
    <Dialog
      max-width="780px"
      :hide-overlay="true"
      :is-dialog-open="dialogs.permissions"
      :title="`${featureName('mentor', 'pluralize').capitalize()} permissons for ${model.title}`"
      @toggle:dialog="dialogs.permissions = !dialogs.permissions"
    >
      <UserPermissions
        :program-id="model.id"
        :existing-data="mentors"
        @updated="onUpdatedPermission"
      />
    </Dialog>
    <FormDialog
      max-width="815px"
      model-type="users"
      :is-dialog-open="dialogs.addPeople.status"
      :button-text="dialogs.addPeople.buttonText"
      :disabled="dialogs.addPeople.disabled"
      :title="`Add ${featureName('user').capitalize()} to ${model.title}`"
      @toggle:form:dialog="dialogs.addPeople.status = !dialogs.addPeople.status"
    >
      <AddPeople
        ref="addPeople"
        :model="model"
        @changeButtonText="onChangeButtonText"
        @selectedItems="onSelectedItems"
        @toggle:dialog="dialogs.addPeople.status = !dialogs.addPeople.status"
      />
    </FormDialog>
  </VRow>
</template>
<script>
import ViewProgramSection from "@/mixins/ViewProgramSection";
import UserPermissions from "../../Permissions";
import AddPeople from "./Add";
import NoRecordCard from "../NoRecordCard";
export default {
  name: "Community",
  mixins: [ViewProgramSection],
  components: {
    UserPermissions,
    AddPeople,
    UserPermissions,
    NoRecordCard,
  },
  data() {
    return {
      tab: 0,
      maxPeoplesOnDashboard: 19,
      buttonText: "Invite",
      dialogs: {
        permissions: false,
        addPeople: {
          status: false,
          disabled: false,
          buttonText: "Invite",
        },
      },
    }
  },
  computed: {
    allUsers() {
      const users = this.model.mentors.concat(this.model.students);
      if(users.length > 0 && this.sortBy == "city.name") {
        return users.sort((a, b) => (a.city===null) - (b.city===null));
      }
      this.$store.commit('program/updateSectionsVisibility', { 
        community: users.length > 0,
      });
      return users;
      
    },
    peoples() {
      if(this.isDashboard) {
        return this.allUsers.length > this.maxPeoplesOnDashboard ? this.allUsers.slice(0, this.maxPeoplesOnDashboard) : this.allUsers;
      }
      return this.allUsers;
    },
    mentors() {
      return this.model.mentors.filter((mentor) => mentor.attachment_meta.type == "mentor");
    },
    students() {
      return this.model.students.filter((student) => student.attachment_meta.type == "student");
    },
  },
  created() {
    this.tab = this.defaultCommunityTab > 0 ? this.defaultCommunityTab : this.tab;
    if(this.$route.params.hasOwnProperty("openAddUsersDialog") && this.$route.params.openAddUsersDialog) {
      this.dialogs.addPeople.status = !this.dialogs.addPeople.status;
    }
  },
  methods: {
    onChangeButtonText(text) {
      this.dialogs.addPeople.buttonText = text;
      this.dialogs.addPeople.disabled = text === "Add";
    },
    onSelectedItems(items) {
      this.dialogs.addPeople.disabled = items.length === 0;
    },
    onUpdatedPermission(userId, permissions) {
      let index = this.mentors.findIndex((mentor) => mentor.id === userId);
      this.$set(this.mentors[index].attachment_meta, "permissions", permissions);
    },
  },
}
</script>
<style lang="scss">
.community-view {
  .v-card__title {
    border-bottom: none !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .v-tab {
    padding: 0 12px;
    min-width: 70px;
  }
  .v-tabs-bar {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>