<template>
  <VRow>
    <VCol cols="12">
      <VRow
        v-for="(section, index) in allSections"
        :key="index"
      >
        <VCol 
          :class="{
            'd-flex align-center py-4': true,
            'mt-n6': index === 0,
          }"
        >
          <span class="label-text text-truncate">
            {{ section.title }}
          </span>
          <VSpacer />
          <ItemActions
            v-if="['huddles', 'community'].indexOf(section.name) === -1"
            is-for-dashboard
            :section="section"
            :model="model"
            :offset-y="section.name == 'faqs'"
            @addSection="$emit('addSection', section)"
            @reorder="$emit('showReorderDialog', section.name)"
            @toggleTab="$emit('toggleTab', section.name)"
            @toggleAttach="$emit('showAttachDialog', section.name)"
            @renameFeature="$emit('showRenameDialog', section.name)"
          />
        </VCol>
        <VCol cols="12">
          <component
            v-if="hideSections.indexOf(section.name) === -1"
            :is="section.name"
            :model="model"
            :sections="sections"
            :is-dashboard="isDashboard"
            :is-tiles-view="isTilesView"
            :sort-by="sortBy"
            @showViewDialog="
              (sectionName, item) => $emit('showViewDialog', sectionName, item)
            "
            @toggleTab="(tab) => $emit('toggleTab', tab)"
            @addSection="$emit('addSection', section)"
            @toggleAttach="$emit('showAttachDialog', section.name)"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import ViewProgramSection from "@/mixins/ViewProgramSection";
import Community from "./Community/index";
import Lessons from "./Lessons";
import Huddles from "./Huddles";
import Contents from "./Content";
import Deliverables from "./Deliverables";
import Pipelines from "./Pipelines";
import Faqs from "./FAQ";
import ItemActions from "./Actions";

export default {
  name: "Dashboard",
  mixins: [ViewProgramSection],
  components: {
    Community,
    Huddles,
    Lessons,
    Contents,
    Deliverables,
    Pipelines,
    Faqs,
    ItemActions,
  },
  data() {
    return {
      hideSections: ["dashboard"],
    };
  },
  computed: {
    allSections() {
      return this.sections.filter(
        (section) => this.hideSections.indexOf(section.name) === -1
      );
    },
  },
};
</script>
