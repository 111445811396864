<template>
  <VCard
    class="d-flex"
    style="padding: 10px;"
  >
    <VCardText>
      <h3 
        class="font-weight-bold pb-2"
        style="font-size: 18px;"
      >
        {{ title }}
      </h3>
      <p class="text-body-1">
        {{ description }}
      </p>
    </VCardText>
    <VCardActions v-if="showAction">
      <VBtn
        outlined
        height="44px"
        color="#0A5894"
        class="no-shadow px-3"
        style="border-radius: 9px; background-color: #FFFFFF;"
        :disabled="disableAction"
        @click="$emit('clicked')"
      >
        <VIcon color="#0A5894">
          {{ actionIcon }}
        </VIcon>
        <span class="text-caption font-weight-bold pl-1">
          {{ actionText }}
        </span> 
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
export default {
  name: "NoRecordCard",
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    actionIcon: {
      type: String,
      default: "add",
    },
    actionText: {
      type: String,
      default: null,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
  },
}
</script>